<template>
  <div>
    <b-card >
      <b-row class="text-center">
        <b-col sm="12" md="3">
          <img
            width="25%"
            style="border-radius:50%;"
            src="@/assets/abdulmajid_qodiriy.jpg"
            alt=""
          />

          <b-card-text>
            <p class="school32 text-center">Aбдулмажид Қодирий <br />(1886 -1938 )</p></b-card-text
          >
        </b-col>
        <b-col md="6">
          <b-card-text>
          <p class="TextBlack17">
            Мажид Қодирий (Қодиров) (Aбдул-Мажид Қодирий), ҳақиқий исми
            Aбдул-Мажидхон Қори Aбдул Қодирхон Қори ўғли (2 октябр 1886 Қори
            ёғди маҳалласи, Тошкент – 5 октябр, 1938, Юнусобод, Тошкент) – олим,
            ўзбек адиби, ўзбек тилидаги биринчи адабиёт, тарих ва математика
            дарсликлари муаллифи, жамоат арбоби ва публицист, Туркистонда
            жадидчилик ҳаракати асосчиларидан бири. Мажид Қодирий (Қодиров)
            Тошкент шаҳрининг Шайхонтоҳур даҳасидаги Қори ёғди маҳалласида,
            руҳоний оиласида, ҳижрий 1304 йил Муҳаррам ойининг 4 кунида таваллуд
            топган. Ески шаҳар мактабини, сўнгра мадрасани тамомлаган. Дастлабки
            меҳнат фаолиятини Тошкентдаги туркий “усли-савтия” (ески усул)
            мактабда бошлаган, кейинчалик “усули-жадидия” (янги усул) ҳамда
            рус-тузем мартабларида ва мадрасаларда таълим берган. Форс, араб,
            усмоний турк, рус ва инглиз тилларини мукаммал билган. 1906 йилдан
            бошлаб рус-тузем мактабларида таълим берган, 1917 йилдаги инқилобдан
            аввал Тошкентдаги ўрта мактабларда дарс берган ҳамда уларда мудирлик
            қилган. 1918-1921 йилларда Тошкентда жадидчилик ҳаракати томонидан
            ташкил етилган “6-нчи Турон мактаби”да мудир. 1918 йилда Коммунистик
            партия аъзолигига қабул қилинган. 1920-1921 йилларда – Тошкентдаги
            табиий ҳамда тарих фанлари педлабараториясида мудирлик қилган. 1921
            йилдан бошлаб Туркистон давлат университетининг Ески шаҳар ишчи
            факултетида ўзбек тили муаллими сифатида ўз меҳнат фаолиятини
            бошлаган. 1923 йилнинг 23 мартида мамлакат тараққиёти йўлидаги
            алоҳида меҳнатлари учун “Меҳнат Қаҳрамони” унвони билан
            тақдирланган. 1923—1925 йилларда “Табиатни муҳофаза қилиш” музейи
            мудири. 1924 йилда Тошкенда жойлашган Вақф шўбаси ходими бўлиб ҳам
            фаолият юритган. 1925 йилдан бошлаб Ўрта Осиё осориатиқаларни сақлаш
            қўмитаси ходими. 1925 йилда Парижда очилган кўргазмада Ўзбекистон
            Республикасининг вакили сифатида иштирок етган. Парижга кетаётиб,
            Берлинда тўхтаб, Германияда таҳсил олаётган ўзбек талабалари билан
            учрашган. 1929 йилнинг бошида Коммунистик партия аъзолигидан маҳрум
            етилди ва 1930 йилда барча масъул лавозимлардан озод етилди. Сабаби,
            1929-1930 йиллардаги пролетариат ҳамда совет мустамлакачиларининг
            ашаддий тарафдорлари томонидан ёғдирилган туҳматлар ҳамда матбуотда
            босилган фелетонлар. Исботи топилмаган гумонлар билан
            айбланган.Мажид Қодирий 1930 йилдан сўнг ҳам мактаб ва Ўрта Осиё
            университетида таълим бериб келган. 1937 йилда НКВД ходимлари
            томонидан “халқ душмани” айби билан қамоққа олинган. Расмий
            ҳужжатларда 1938 йилнинг 2 апрел куни ҳибсга олинган деб
            кўрсатилган. 1938 йилнинг 12 сентябрида “тройка” томонидан олий
            жазога ҳукм қилинган, ҳукм (СССР Олий судининг Ҳарбий коллегияси,
            05.10.1938 — Олий жазо ҳукми) Туркистон тарихидаги енг қонли кун –
            1938 йилнинг 5 октябр куни ижрога келтирилган. Мажид Қодирий СССР
            Олий судининг Ҳарбий коллегияси томонидан 13.02.1958 санасида
            далиллар йўқлиги сабабли реаблитация қилинган ва Коммунистик партия
            аъзолигига тикланган. Мажид Қодирий ҳамда аёллари Меҳринисохон
            Мирсагатовалар оилаларида 6 нафар фарзандлари бўлган: ўғиллари
            Қуддусхон, Рамзихон, Рашодхон, Еркинхон, Ўткирхон ва қизлари
            Нафисахон.
          </p>
        </b-card-text>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>
    </b-card>
    <!-- <b-card style="background-color:#EEEEEE">
      <b-container style="background-color:#EEE4CD;border-radius:20px">
        
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              @click="$router.push({ name: 'jadidchilar_info' })"
              variant="outline-danger"
            >
              {{ $t("Back") }}
            </b-button>
          </b-col>
          
        </b-row>
      </b-container>
    </b-card> -->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
